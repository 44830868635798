<template>
  <el-row class="show-quill">
    <div class="ql-container ql-snow">
      <div class="ql-editor" v-html="content">
      </div>
    </div>
  </el-row>
</template>

<script>
export default {
  name:'QuillShow',
  props: {
    content: {
      type: String,
      default:''
    }
  }
}
</script>

<style lang="scss" scoped>
  .show-quill .ql-container.ql-snow {
    border: none !important;
  }
</style>